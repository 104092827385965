import {drawerWidth} from '@utils/constants';
import { makeStyles } from '@material-ui/core/styles';

import {fontSizePxToRem} from '@client/theme/typography';



const useStyles = makeStyles((theme) => ({
    mainDrawer: {
        // boxShadow: 'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
        transition: 'all 250ms ease-in-out 0s',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        // backgroundImage: `linear-gradient(rgba(34, 42, 69, 0.96), rgba(34, 42, 69, 0.96)), url(https://matx-react.ui-lib.com/assets/images/sidebar/sidebar-bg-dark.jpg)`,
        background: theme.colors.drawer,
    },
	drawer: {
        whiteSpace: 'nowrap',
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
        ...theme.mixins.toolbar,
        background: theme.palette.primary.main,

    },
    routesList: {
        margin: theme.spacing(2, 0),
        paddingLeft: theme.spacing(3),
    },
    mainText: {
        color: theme.colors.drawerText,
        fontSize: fontSizePxToRem(14),
        fontWeight: '600',
        '& span': {
            marginLeft: theme.spacing(2),
        }
    },
    appIcon: {
        '& svg':{
            color: theme.colors.drawerText,
            fontSize: fontSizePxToRem(24),
        }
    },
    noShadow: {
        borderRight: 'none',
        boxShadow: 'none',
    },
    brandLogo: {
        height: '2rem',
        marginLeft: theme.spacing(1),
        maxWidth: '100%',
    },
    drawerIcon: {
        color: theme.colors.lightest,
    },
    highlight: {
        // borderRadius: theme.spacing(1),
        // background: lighten(theme.palette.primary.main, 0.4),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default useStyles;
