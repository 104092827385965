import React, {useState, useEffect} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MyComponent from 'react-fullpage-custom-loader'
import random from 'lodash/random';
import size from 'lodash/size';
import { backdropLoader, loadingMessages } from '@utils/constants'

import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';





const SimpleBackdrop = ({ showText = true}) => {
    const classes = useStyles();
    const theme = useTheme();


    
 
    const backDrop = () =>  (
        <Backdrop className={classes.backdrop} open={true}>
                {/* <img src='https://brainbout.in/logo.8355dfe736a77a359257.png' className={classes.brandLogo} /> */}
                
                <div className={classes.backdropMain}>
                <CircularProgress  className={classes.progressBar} color="inherit" /> 
                {/* <img src='https://brainbout.in/logo.8355dfe736a77a359257.png' className={classes.brandLogo} />  */}
                </div>
            </Backdrop>
    );

    const loader = () => {
        return (
            <MyComponent
                sentences={showText ? loadingMessages: []}
                fadeIn
                loaderSize={'null'}
                wrapperBackgroundColor={theme.colors.background}
                loaderType={backdropLoader[random(0, size(backdropLoader)-1)]}
                textStyles={{color: theme.palette.secondary.main, marginTop: theme.spacing(10)}}
                color={theme.palette.primary.main}
            />
        );
    }



    return loader();
}


export default SimpleBackdrop;


