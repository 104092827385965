import React from 'react';
import { useSelector, useDispatch} from 'react-redux';


import Appbar from '../appbar';
import Drawer from '../drawer';


import {toggleDrawer} from '@actions/app';
import useStyles from './styles';



const ScreenLayout = (props) => {
    const classes = useStyles();
    const {drawerOpen} = useSelector(state => state.app);
    const dispatch = useDispatch();


    const handleDrawerOpen = () => {
        dispatch(toggleDrawer(true))
    }
    
    const handleDrawerClose = () => {
        dispatch(toggleDrawer(false))
    }

    return (
        <div className={classes.root}>
            <Appbar handleDrawerOpen={handleDrawerOpen} open={drawerOpen} />
            <Drawer handleDrawerClose={handleDrawerClose} open={drawerOpen} />
            <main className={classes.content}>
                {props.children}
            </main>
        </div>
    );
};


export default ScreenLayout;
