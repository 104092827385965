import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
// import RootSagas from '../sagas';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import { loadReduxData, saveReduxData } from '@utils/helpers/storage'
import throttle from 'lodash/throttle';



// let sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk];
// const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
	const { createLogger } = require('redux-logger');
	const logger = createLogger({
		collapsed: true
	});
    middlewares.push(logger);
}

const bindMiddleware = () => {
    return applyMiddleware(...middlewares);
}


const enhancer = composeWithDevTools({
    // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
})(bindMiddleware());


const persistedState = loadReduxData();

function getStore(preloadedState={}) {
    let store, composeEnhancer;

    if (process.env.NODE_ENV !== "production") {
        composeEnhancer = composeWithDevTools({
            name: `Redux`,
            realtime: true,
            trace: true,
            traceLimit: 25
        });
    } else {
        composeEnhancer = global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    store = createStore(
        reducers,
        persistedState,
        composeEnhancer(bindMiddleware()),
    );

    
    

    store.subscribe(throttle(() => {
        saveReduxData(store.getState());
    }, 1000));

    if (module && module['hot']) {
        module['hot'].accept('../reducers', () => {
            const nextReducer = require('../reducers/index').default;
            store.replaceReducer(nextReducer);
        });
    }
    // sagaMiddleware.run(RootSagas);
    // const persistor = persistStore(store);
    return store;
}

export default getStore;

