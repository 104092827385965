import actionTypes from '@actions/actionTypes';
import {getReducerPayload} from './getPayload';



const userPreloadedState = {
    isLoading: false,
    list: [],
    error: {},
}


const categoryReducer = (state = userPreloadedState, action) => {
    const {payload, error} = action;
    switch (action.type) {
        case actionTypes.FETCH_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categories: getReducerPayload(payload),
            }
       
        default:
            return state;
    }
}


export default categoryReducer;
