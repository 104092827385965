import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import AppRouter from '@routers';
import Snackbar from '@shared/Snackbar';




const App = () => {
    const { activeTheme } = useSelector(state => state.app);
    return (
        <Router>
            <CssBaseline />
            <ThemeProvider theme={theme(activeTheme)}>
                <Snackbar />
                <AppRouter />
            </ThemeProvider>
        </Router>
    );
}
export default App;
