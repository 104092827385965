import actionTypes from '@actions/actionTypes';
import {snackbarConfig} from '@utils/constants';
import { setData, getData, removeData} from '@utils/helpers/storage';

let appLocalPreloadedState = getData(process.env.APP_CONFIG) || {};
if (appLocalPreloadedState && appLocalPreloadedState.length){
    appLocalPreloadedState = JSON.parse(appLocalPreloadedState);
}


const appPreloadedState = {
    appLoading: false,
    activeTheme: 'light',
    drawerOpen: 'true',
    snackbar: {
        visible: false,
        ...snackbarConfig,
    },
    accountImpersonated: {},
    ...appLocalPreloadedState,
};


const authenticationReducer = (state = appPreloadedState, action) => {
    const {payload} = action;
    let reducerState;

    switch (action.type) {
        case actionTypes.SHOW_SNACKBAR:
            reducerState = {
                ...state,
                snackbar: {
                    visible: true,
                    ...snackbarConfig,
                    ...payload,
                }
            }
            setData(process.env.APP_CONFIG, reducerState);
            return reducerState;

        case actionTypes.HIDE_SNACKBAR:
            reducerState = {
                ...state,
                snackbar: { ...state.snackbar, message: '', visible: false },
            }
            setData(process.env.APP_CONFIG, reducerState);
            return reducerState

        case actionTypes.APP_LOADING:
            reducerState = {
                ...state,
                appLoading: payload,
            }

            setData(process.env.APP_CONFIG, reducerState);
            return reducerState;

        case actionTypes.TOGGLE_THEME:
            reducerState = {
                ...state,
                activeTheme: state.activeTheme === 'light' ? 'dark': 'light',
            }

            setData(process.env.APP_CONFIG, reducerState);
            return reducerState

        case actionTypes.TOGGLE_DRAWER:
            reducerState = {
                ...state,
                drawerOpen: payload,
            }

            setData(process.env.APP_CONFIG, reducerState);
            return reducerState;

        case actionTypes.SET_IMPERSONATION:
            reducerState = {
                ...state,
                accountImpersonated: payload,
            }
            setData('bbq_account_token', payload.id);
            setData(process.env.APP_CONFIG, reducerState);
            return reducerState;

        case actionTypes.REMOVE_IMPERSONATION:
            reducerState = {
                ...state,
                accountImpersonated: {},
            }
            removeData('bbq_account_token');
            setData(process.env.APP_CONFIG, reducerState);
            return reducerState;


        default:
            return state;
    }
}


export default authenticationReducer;
