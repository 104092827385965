const styles = {
    errorRoot: {
        width: '100%',
        marginTop: '20vh',
    },

    errorImage: {
        width: '100%',
        maxWidth: '15rem',
        display: 'block',
        margin: 'auto',
    },

    errorTitle: {
        textAlign: 'center',
        color: '#d0643a',
        fontFamily: 'Nunito',
        fontSize: '2.8rem',
        marginBottom: '0',
    },

    errorDescription: {
        textAlign: 'center',
        color: '#e85757',
        fontFamily: 'Nunito',
        fontSize: '1.8rem',
        margin: '1rem 0',
    },

    errorDisclaimer: {
        textAlign: 'center',
        color: '#709ac5',
        marginTop: '4rem',
        fontFamily: 'Nunito',
        fontSize: '1.4rem',
    },
}

export default styles;





