import React from "react";
import styles from './styles';
import {
    GENERAL_ERROR_TITLE_MSG,
    GENERAL_ERROR_DESCRIPTION_MSG,
    GENERAL_ERROR_FOOTER_MSG,
} from "@utils/constants";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            // Error path
            return (
                <div style={styles.errorRoot}>
                    <img style={styles.errorImage} src='https://res.cloudinary.com/dczzp2imu/image/upload/engage4more/404.png' />
                    <h1 style={styles.errorTitle}>{GENERAL_ERROR_TITLE_MSG}</h1>
                    <h4 style={styles.errorDescription}>{GENERAL_ERROR_DESCRIPTION_MSG}</h4>
                    <p style={styles.errorDisclaimer}>{GENERAL_ERROR_FOOTER_MSG}</p>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
