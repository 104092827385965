


export const getReducerPayload = (payload = {}, state = {}) => {
    if (payload && payload.data && payload.data.data) {
        if (payload.data.data.courses) return payload.data.data.courses;
        else return payload.data.data;
    }
    if (payload && payload.data) {
        return payload.data;
    } else if (payload)
        return payload;

    return state;
}


export const getErrorResponse = error => {
    let err = error;
    if (err.response) err  = err.response;
    if (err.data) err = err.data;
    if (err.error) err = err.error;
    return err
}
