import actionTypes from './actionTypes';
import { getCorporateName } from '@utils/helpers/misc';

export const hideSnack = () => ({ type: actionTypes.HIDE_SNACKBAR });
export const changeTheme = () => ({ type: actionTypes.TOGGLE_THEME });

export const showSnack = (payload) => ({
    type: actionTypes.SHOW_SNACKBAR,
    payload,
});

export const toggleDrawer = (payload) => ({ 
    type: actionTypes.TOGGLE_DRAWER, 
    payload 
});

export const setImpersonation = (payload) => async dispatch => {
    dispatch({ type: actionTypes.SET_IMPERSONATION, payload});
    dispatch(showSnack({
        message: `Impersonated: ${getCorporateName(payload.account_name)}`,
        type: 'success'
    }));
}

export const removeImpersonation = () => async dispatch => {
    dispatch({type: actionTypes.REMOVE_IMPERSONATION, });
    dispatch(showSnack({
        message: `Account Impersonation Removed`,
        type: 'success'
    }));
}

