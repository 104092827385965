import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import MuiAlert from '@material-ui/lab/Alert';
import {hideSnack} from '@actions/app';
import {useSelector, useDispatch} from 'react-redux';




const transitionType =  {
    grow: GrowTransition,
    fade: Fade,
    slide: SlideTransition,
}



function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

export default function TransitionsSnackbar() {
    const {snackbar} = useSelector(state => state.app);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideSnack());
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <div>
            <Snackbar
                open={!!snackbar.visible}
                onClose={handleClose}
                autoHideDuration={snackbar.transitionTime}
                TransitionComponent={transitionType[snackbar.transitionType  || 'fade']}
                message={snackbar.message}
                anchorOrigin={snackbar.anchorOrigin}
            >
                <Alert onClose={handleClose} severity={snackbar.type}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
