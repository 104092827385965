import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import { Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { isLoggedIn } from '@utils/helpers/checkAuth';
import Backdrop from '@components/layout/Backdrop';
import apps from '@apps';
import { size, head } from 'lodash';

const AppRoutes = (props) => {
    const auth = useSelector(state => state.auth);
    const { accountImpersonated } = useSelector(state => state.app);
    const isAuthenticated =  auth.user.id || isLoggedIn();
    
    const isImpersonated = () => {
        let impersonated = false;
        if (accountImpersonated.id || size(auth.user.account_id)) {
            impersonated = true;
        }
        return impersonated;
    }

    const getRoutes = () => {
        if (isImpersonated()) {
            return Object.values(apps.impersonatedRoutes)
        } else return Object.values(apps.private)
    }


    const getRedirectedPath = () => {
        let routes = isImpersonated() ? 'impersonatedRoutes': 'private';
        return head(Object.values(apps[routes])).path[0]
    }


    return (
        <Suspense fallback={<Backdrop />}>
            <Switch>
                {Object.values(apps.public).map((route, index) => {
                    return (
                        <PublicRoute
                        //add restricted to check so that if already login then redirect to dashboard
                            key={index}
                            restricted={!!isAuthenticated}
                            redirect={getRedirectedPath()}
                            exact
                            path={route.path}
                            component={route.component}
                        />
                    )
                })}
                {getRoutes().map((route, index) => {
                    return (
                        <PrivateRoute
                            key={index}
                            isAuthenticated={!!isAuthenticated}
                            path={route.path}
                            component={route.component}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
};

AppRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

AppRoutes.defaultProps = {
    isAuthenticated: false,
};

export default AppRoutes;
