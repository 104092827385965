import actionTypes from '@actions/actionTypes';
import {getReducerPayload, getErrorResponse} from './getPayload';
import { setData, removeData, getData} from '@utils/helpers/storage';
import { size } from 'lodash';




const userPreloadedState = {
    isLoading: false,
    user: {},
    error: {},
}

const localUserState = getData('bbq_user');
if (size(localUserState)){
    userPreloadedState.user = JSON.parse(localUserState);
}


const authenticationReducer = (state = userPreloadedState, action) => {
    const {payload, error} = action;
    switch (action.type) {
        case actionTypes.AUTHENTICATE:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.AUTHENTICATE_SUCCESS:
            const userState = getReducerPayload(payload);
            if (userState.account_id){
                setData('bbq_account_token', userState.account_id);
            }
            setData('bbq_user', userState);
            setData('bbq_token', userState.token);
            return {
                ...state,
                isLoading: false,
                user: userState,
                error: {},
            }
        case actionTypes.AUTHENTICATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                user: {},
                error: getErrorResponse(error),
            }
        case actionTypes.SIGN_OUT_USER:
            removeData('bbq_user');
            removeData('bbq_token');
            removeData('bbq_account_token');
            return {
                isLoading: false,
                user: {},
                error: {},
            }
        default:
            return state;
    }
}


export default authenticationReducer;
