import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    backdropMain: {
        // background: 'url(https://brainbout.in/logo.8355dfe736a77a359257.png)',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        
        position: 'relative',
    },

    progressBar: {

    },
    textStyles: {
        color: theme.palette.primary.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        height: '100%',
        minHeight: '100vh',
        position: 'relative',
    },
    brandLogo: {
        height: '4rem',
        maxWidth: '100%',
        position: 'relative',
    },
  
}));



export default useStyles;
