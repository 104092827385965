import React, {lazy} from 'react';
import {omitBy} from 'lodash'

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import PersonPinCircleRoundedIcon from '@material-ui/icons/PersonPinCircleRounded';
import GamesRoundedIcon from '@material-ui/icons/GamesRounded';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import BackupRoundedIcon from '@material-ui/icons/BackupRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import NotificationImportantRoundedIcon from '@material-ui/icons/NotificationImportantRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FeedbackIcon from '@material-ui/icons/Feedback';



//public apps
export const SignIn = lazy(() => import('@pages/Signin'));
export const Login = lazy(() => import('@pages/Login'));

//private apps
export const Corporate = lazy(() => import('@pages/Corporate'));
export const Location = lazy(() => import('@pages/Location'));
export const Category = lazy(() => import('@pages/Category'));
export const Question = lazy(() => import('@pages/Question'));
export const Event = lazy(() => import('@pages/Event'));
export const User = lazy(() => import('@pages/User'));
export const Upload = lazy(() => import('@pages/Upload'));
export const Announcement = lazy(() => import('@pages/Announcement'));
export const Dashboard = lazy(() => import('@pages/Dashboard'));
export const Participation = lazy(() => import('@pages/Participation'));
export const EventParticipation = lazy(() => import('@pages/EventParticipation'));
export const TeamParticipation = lazy(() => import('@pages/TeamParticipation'));
export const TeamEventParticipation = lazy(() => import('@pages/TeamEventParticipation'));
export const Competition = lazy(() => import('@pages/Competition'));
export const Media = lazy(() => import('@pages/Media'));
export const Feedback = lazy(() => import('@pages/Feedback'));









const apps = {
    public: {
        //public apps doesnt appears in drawer and so label and icons are not needed (yet)!
        signIn: { path: ["/", "/login"], component: SignIn },
    },
    private: {
        dashboard: {
            path: ["/dashboard"],
            component: Dashboard,
            icon: <DashboardIcon />,
            label: "Dashboard",
            adminAccess: true,
            showNavigation: true,
        },

        corporate: {
            path: ["/corporate"],
            component: Corporate,
            icon: <BusinessRoundedIcon />,
            label: "Corporates",
            adminAccess: true,
            showNavigation: true,
        },

        question: {
            path: ["/question"],
            component: Question,
            icon: <ContactSupportRoundedIcon />,
            label: "Questions",
            showNavigation: true,
        },

        event: {
            path: ["/event", "/events/series"],
            component: Event,
            label: "Events",
            icon: <EventAvailableRoundedIcon />,
            showNavigation: true,
            nestedNavigation: [
                {
                    title: "Series",
                    filterType: "event_type",
                    filterParams: "series",
                },
                {
                    title: "Quizlets",
                    filterType: "event_type",
                    filterParams: "quizlet",
                },
                {
                    title: "Adda",
                    filterType: "event_type",
                    filterParams: "adda",
                },
            ],
        },

        user: {
            path: ["/user", "/user/guests", "/user/participant"],
            component: User,
            icon: <PeopleRoundedIcon />,
            label: "Users",
            showNavigation: true,
            nestedNavigation: [
                {
                    title: "Account User",
                    filterType: "role",
                    filterParams: "participant",
                },
                { title: "Guest", filterType: "role", filterParams: "guest" },
            ],
        },
        participation: {
            path: ["/participation"],
            component: Participation,
            icon: <EventAvailableRoundedIcon />,
            label: "Participation ",
            showNavigation: true,
            nestedNavigation: [
                {
                    title: "Competitions",
                    filterType: "role",
                    filterParams: "participant",
                },
                { title: "Events", route: "event-participation" },
                { title: "Guest", filterType: "role", filterParams: "guest" },
                // { title: "Teams", route: "team-participation"},
                // { title: "Teams Events", route: "team-event-participation" },
                // { title: "Competitions", route: "competition" },
            ],
        },
        team_participation: {
            path: ["/team-participation"],
            component: TeamParticipation,
            icon: <EventAvailableRoundedIcon />,
            label: "Teams",
            showNavigation: true,
            nestedNavigation: [
                {
                    title: "Competitions",
                    filterType: "role",
                    filterParams: "participant",
                },
                { title: " Events", route: "team-event-participation" },
            ],
        },
        category: {
            path: ["/category"],
            component: Category,
            icon: <GamesRoundedIcon />,
            label: "Categories",
            adminAccess: true,
            showNavigation: true,
        },

        location: {
            path: ["/location"],
            component: Location,
            icon: <PersonPinCircleRoundedIcon />,
            label: "Locations",
            adminAccess: true,
            showNavigation: true,
        },
        media: {
            path: ["/media"],
            component: Media,
            icon: <BackupRoundedIcon />,
            label: "Media",
            showNavigation: true,
        },
        feedback: {
            path: ["/feedback"],
            component: Feedback,
            icon: <FeedbackIcon />,
            label: "Feedback",
            showNavigation: true,
        },
        announcement: {
            path: ["/announcement"],
            component: Announcement,
            icon: <NotificationImportantRoundedIcon />,
            label: "Announcement",
            showNavigation: true,
        },

        //custom paths
        competition: {
            path: ["/competition"],
            component: Competition,
            icon: <EventAvailableRoundedIcon />,
            label: "Competition",
        },
        event_participation: {
            path: ["/event-participation"],
            component: EventParticipation,
            icon: <EventAvailableRoundedIcon />,
            label: "Event Participation",
        },
        // team_participation: {
        //     path: ["/team-participation"],
        //     component: TeamParticipation,
        //     icon: <EventAvailableRoundedIcon />,
        //     label: "Team Participation",
        // },
        team_event_participation: {
            path: ["/team-event-participation"],
            component: TeamEventParticipation,
            icon: <EventAvailableRoundedIcon />,
            label: "Team Event Participation",
        },
    },
};


apps.impersonatedRoutes = { ...omitBy(apps.private, 'adminAccess' )}

export default apps;
