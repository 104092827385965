var CryptoJS = require("crypto-js");
import {encryptionKey} from '@utils/constants';


const storeInStorage = (key, value) => {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    value = CryptoJS.AES.encrypt(value, encryptionKey).toString();
    localStorage.setItem(key, value);
};

const getFromStorage = (key) => {
    let ciphertext = localStorage.getItem(key);
    if (ciphertext && ciphertext.length){
        const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else return null;
};

const removeFromStorage = (key) => {
    localStorage.removeItem(key);
};

export const setToken = (tokenObj) => {
    storeInStorage('accessToken', tokenObj.accessToken);
    storeInStorage('refreshToken', tokenObj.refreshToken);
};

export const getAccessToken = () => {
    return getFromStorage('accessToken');
};

export const getRefreshToken = () => {
    return getFromStorage('refreshToken');
};

export const clearAccessRefreshToken = () => {
    return (
        removeFromStorage('accessToken') &&
        removeFromStorage('refreshToken')
    );
};

export const setData = (key, value) => storeInStorage(key, value);
export const getData = (key) => getFromStorage(key);
export const removeData = (key) => removeFromStorage(key);

export const saveReduxData = (state) => {
    try{
        const serializedState = JSON.stringify(state);
        storeInStorage(process.env.REDUX_STORE, serializedState);
    } catch(err){
        return undefined;
    }
}

export const loadReduxData = (state) => {
    try {
        const serializedState = localStorage.getItem(process.env.REDUX_STORE); 
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }

}