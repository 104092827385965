import { head, last, split, map, startCase, startsWith, forEach, size } from 'lodash'



export const getCorporateName = (account_name) => {
    return startCase(head(split(account_name, '.')))
} 

export const getSearchState = (defaultState) => {
    const state = {};
    Object.keys(defaultState).forEach(item => {
        state[item] = {
            ...defaultState[item],
            results: [],
            searchedQuery: '',
            pagination: {
                offset: -1,
                sort_by: defaultState[item].sort_by || '',
                limit: 50,
            }
        }
    });

    return state;
}

export const getQueryParams = query => {
    if (startsWith(query, '?')){
        query = query.slice(1);
    }

    let queryParams = {} 
    forEach(split(query, '&'), item => {
        item = split(item, '=');
        if (size(head(item))){
            queryParams[head(item)] = last(item);
        }
    })
    return queryParams;
}

export const getQueryFromUrl = () => {
    let query = {};
    let path = (window.location.pathname + window.location.search).substr(1)
    path = split(path, '?');
    if (size(path) > 1) {
        query = getQueryParams(last(path));
    }
    return query;
}
