import axios from './instance';
import {
    USER_LOGIN,
    UPLOAD_FILE,
    CORPORATE_LIST,
    CORPORATE_ADD,
    CORPORATE_UPDATE,
    CORPORATE_DELETE,
    LOCATION_LIST,
    LOCATION_ADD,
    LOCATION_UPDATE,
    LOCATION_DELETE,
    CATEGORY_LIST,
    CATEGORY_ADD,
    CATEGORY_UPDATE,
    CATEGORY_DELETE,
    UPLOAD_LIST,
    UPLOAD_ADD,
    UPLOAD_UPDATE,
    UPLOAD_DELETE,
    ANNOUNCEMENT_LIST,
    ANNOUNCEMENT_ADD,
    ANNOUNCEMENT_UPDATE,
    ANNOUNCEMENT_DELETE,
    USER_LIST,
    USER_ADD,
    USER_UPDATE,
    USER_DELETE,
    EVENT_LIST,
    EVENT_ADD,
    EVENT_UPDATE,
    EVENT_DELETE,
    QUESTION_LIST,
    QUESTION_ADD,
    QUESTION_UPDATE,
    QUESTION_DELETE,
    COMPETITION,
    MEDIA,
    PARTICIPATION,
    EVENT_PARTICIPATION,
    TEAM_PARTICIPATION,
    TEAM_EVENT_PARTICIPATION,
    BACKEND_URL,
    FEEDBACK,
    USER_DOWNLOAD,
    CATEGORY_DOWNLOAD,
    QUESTION_DOWNLOAD,
    PARTICIPATION_DOWNLOAD,
} from "./api-config";



//api list

export const generic = (params, urlSuffix) => {
    let url = `${BACKEND_URL}/${urlSuffix}`;
    return axios.get(url, {params})
}



//auth
export const login = (params) => axios.post(USER_LOGIN, params);
export const uploadFile = (params) => axios.post(UPLOAD_FILE, params);


//corporates
export const corporateList = (params) => axios.get(CORPORATE_LIST, {params});
export const corporateAdd = (params) => axios.post(CORPORATE_ADD, params);
export const corporateEdit = (params, id) => axios.put(`${CORPORATE_UPDATE}/${id}`,params);
export const corporatePublish = (id ) => axios.put(`${CORPORATE_UPDATE}/${id}/publish`,);
export const corporateUnPublish = (id ) => axios.put(`${CORPORATE_UPDATE}/${id}/unpublish`,);
export const corporateDelete = (id) => axios.delete(`${CORPORATE_DELETE}/${id}`);


//location
export const locationList = (params) => axios.get(LOCATION_LIST, {params});
export const locationAdd = (params) => axios.post(LOCATION_ADD, params);
export const locationEdit = (params, id ) => axios.put(`${LOCATION_UPDATE}/${id}`, params);
export const locationPublish = (id ) => axios.put(`${LOCATION_UPDATE}/${id}/publish`,);
export const locationUnPublish = (id ) => axios.put(`${LOCATION_UPDATE}/${id}/unpublish`,);
export const locationDelete = (id) => axios.delete(`${LOCATION_DELETE}/${id}`);


//category
export const categoryList = (params) => axios.get(CATEGORY_LIST, {params});
export const categoryAdd = (params) => axios.post(CATEGORY_ADD, params);
export const categoryEdit = (params, id ) => axios.put(`${CATEGORY_UPDATE}/${id}`, params);
export const categoryPublish = (id ) => axios.put(`${CATEGORY_UPDATE}/${id}/publish`,);
export const categoryUnPublish = (id ) => axios.put(`${CATEGORY_UPDATE}/${id}/unpublish`,);
export const categoryDelete = (id) => axios.delete(`${CATEGORY_DELETE}/${id}`);



//upload
export const uploadList = (params) => axios.get(UPLOAD_LIST, {params});
export const uploadAdd = (params) => axios.post(UPLOAD_ADD, params);
export const uploadEdit = (params, id ) => axios.put(`${UPLOAD_UPDATE}/${id}`, params);
export const uploadDelete = (id) => axios.delete(`${UPLOAD_DELETE}/${id}`);


//announcement
export const announcementList = (params) => axios.get(ANNOUNCEMENT_LIST, {params});
export const announcementAdd = (params) => axios.post(ANNOUNCEMENT_ADD, params);
export const announcementEdit = (params, id ) => axios.put(`${ANNOUNCEMENT_UPDATE}/${id}`, params);
export const announcementPublish = (id ) => axios.put(`${ANNOUNCEMENT_UPDATE}/${id}/publish`,);
export const announcementUnPublish = (id ) => axios.put(`${ANNOUNCEMENT_UPDATE}/${id}/unpublish`,);
export const announcementDelete = (id) => axios.delete(`${ANNOUNCEMENT_DELETE}/${id}`);


// user
export const userList = (params) => axios.get(USER_LIST, {params});
export const userAdd = (params) => axios.post(USER_ADD, params);
export const userEdit = (params, id ) => axios.put(`${USER_UPDATE}/${id}`, params);
export const userPublish = (id ) => axios.put(`${USER_UPDATE}/${id}/publish`,);
export const userUnPublish = (id ) => axios.put(`${USER_UPDATE}/${id}/unpublish`,);
export const userDelete = (id) => axios.delete(`${USER_DELETE}/${id}`);



// event
export const eventList = (params) => axios.get(EVENT_LIST, {params});
export const eventAdd = (params) => axios.post(EVENT_ADD, params);
export const eventPublish = (id ) => axios.put(`${EVENT_UPDATE}/${id}/publish`,);
export const eventUnPublish = (id ) => axios.put(`${EVENT_UPDATE}/${id}/unpublish`,);
export const eventDelete = (id) => axios.delete(`${EVENT_DELETE}/${id}`);
export const eventEdit = (params, id ) => axios.put(`${EVENT_UPDATE}/${id}`, params);
export const saveTargeting = (params, id) => axios.put(`${EVENT_UPDATE}/${id}/targeting`, params);
export const eventBulkDelete = (params) => axios.delete(EVENT_UPDATE, { data: params });

//competition
export const competition = (params) => axios.get(COMPETITION, { params });
export const participation = (params) => axios.get(PARTICIPATION, { params });
export const eventParticipation = (params) => axios.get(EVENT_PARTICIPATION, { params });
export const teamEventParticipation = params => axios.get(TEAM_EVENT_PARTICIPATION, { params });
export const teamParticipation = params => axios.get(TEAM_PARTICIPATION, { params });
export const participationAnswers = (params) => axios.get(`${PARTICIPATION}/${params}`);



// question
export const questionList = (params) => axios.get(QUESTION_LIST, {params});
export const questionAdd = (params) => axios.post(QUESTION_ADD, params);
export const questionEdit = (params, id) => axios.put(`${QUESTION_UPDATE}/${id}`, params);
export const questionPublish = (id ) => axios.put(`${QUESTION_UPDATE}/${id}/publish`,);
export const questionUnPublish = (id ) => axios.put(`${QUESTION_UPDATE}/${id}/unpublish`,);
export const questionDelete = (id) => axios.delete(`${QUESTION_DELETE}/${id}`);
export const getQuestion = (id) => axios.get(`${QUESTION_LIST}/${id}`);
export const fetchQuestionById = (query) => axios.get(`${QUESTION_LIST}${query}`);
export const questionBulkDelete = (params) => axios.delete(QUESTION_LIST, { data: params});
export const questionBulkPublish = (params) => axios.put(`${QUESTION_LIST}/publish`, params);
export const questionBulkUnpublish = (params) => axios.put(`${QUESTION_LIST}/unpublish`, params);
export const questionBulkUpdate = (params) => axios.put(`${QUESTION_LIST}/update`, params);
export const questionBatchUpload = (params) => axios.post(`${QUESTION_LIST}/upload`, params);


// media
export const mediaList = (params) => axios.get(MEDIA, { params });
export const mediaEdit = (params, id) => axios.put(`${MEDIA}/${id}`, params);
export const mediaDelete = (id) => axios.delete(`${MEDIA}/${id}`);
export const mediaAdd = async (params) => {
    const media = []
    for (const item of params) {
        const response = await axios.post(MEDIA, item);
        media.push(response.data ? response.data: response)
    }
    return media
}



//feedback
export const feedbackList = (params) => axios.get(FEEDBACK, {params})
export const feedbackDelete = (id) => axios.delete(`${FEEDBACK}/${id}`);




//download
export const userDownload = (params) => axios.get(USER_DOWNLOAD, { params });
export const categoryDownload = (params) => axios.get(CATEGORY_DOWNLOAD, { params });
export const questionDownload = (params) => axios.get(QUESTION_DOWNLOAD, { params });
export const participationDownload = (params) => axios.get(PARTICIPATION_DOWNLOAD, { params });
export const eventsParticipationDownload = (params) => axios.get(EVENTS_PARTICIPATION_DOWNLOAD, { params });
export const teamParticipationDownload = (params) => axios.get(TEAM_PARTICIPATION_DOWNLOAD, { params });
export const teamEventsParticipationDownload = params => axios.get(TEAM_EVENTS_PARTICIPATION_DOWNLOAD, { params });



const apis = {
    authenticate: login,
    corporate: {
        list: corporateList,
        add: corporateAdd,
        update: corporateEdit,
        delete: corporateDelete,
        publish: corporatePublish,
        unpublish: corporateUnPublish,
    },
    location: {
        list: locationList,
        add: locationAdd,
        update: locationEdit,
        delete: locationDelete,
        publish: locationPublish,
        unpublish: locationUnPublish,
    },
    upload: {
        list: uploadList,
        add: uploadAdd,
        update: uploadEdit,
        delete: uploadDelete,
    },
    category: {
        list: categoryList,
        add: categoryAdd,
        update: categoryEdit,
        delete: categoryDelete,
        publish: categoryPublish,
        unpublish: categoryUnPublish,
    },
    announcement: {
        list: announcementList,
        add: announcementAdd,
        update: announcementEdit,
        delete: announcementDelete,
        publish: announcementPublish,
        unpublish: announcementUnPublish,
    },
    question: {
        list: questionList,
        add: questionAdd,
        update: questionEdit,
        delete: questionDelete,
        publish: questionPublish,
        unpublish: questionUnPublish,
        bulkDelete: questionBulkDelete,
        bulkPublish: questionBulkPublish,
        bulkUnpublish: questionBulkUnpublish,
        bulkUpdate: questionBulkUpdate,
    },
    event: {
        list: eventList,
        add: eventAdd,
        update: eventEdit,
        delete: eventDelete,
        publish: eventPublish,
        unpublish: eventUnPublish,
        targeting: saveTargeting,
        bulkDelete: eventBulkDelete,
    },
    user: {
        list: userList,
        add: userAdd,
        update: userEdit,
        delete: userDelete,
        publish: userPublish,
        unpublish: userUnPublish,
    },
    competition: {
        list: competition,
    },
    participation: {
        list: participation,
        event: eventParticipation,
    },
    event_participation: {
        list: eventParticipation,
    },
    team_event_participation: {
        list: teamEventParticipation,
    },
    team_participation: {
        list: teamParticipation,
    },
    leaderboard: {
        list: generic,
    },
    media: {
        list: mediaList,
        add: mediaAdd,
        update: mediaEdit,
        delete: mediaDelete,
    },
    feedback: {
        list: feedbackList,
        delete: feedbackDelete,
    },
};





export default apis;

