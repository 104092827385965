import { combineReducers } from 'redux';
import auth from './auth';
import app from './app';
import admin from './admin';
import category from './category';
import dashboard from './dashboard';
import location from './location';
import competition from './competition';



// export default rootReducer;
export default combineReducers({
    app,
    admin,
    auth,
    category,
    location,
    dashboard,
    competition,
});


