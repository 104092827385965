import { makeStyles } from '@material-ui/core/styles';
import {drawerWidth} from '@utils/constants';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        // backgroundColor: theme.palette.common.white,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: theme.colors.lightest,
    },
    hide: {
        display: 'none',
    },
    rightContainer: {
        position: 'relative',
        marginLeft: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            width: 'auto',
        }
    },
    brandLogo: {
        height: '2rem',
        maxWidth: '100%',
    },
    appBarIcon: {
        color: theme.colors.lightest,
    },
    userName: {
        marginRight: theme.spacing(2),
    },
    impersonateAccount: {
        marginLeft: theme.spacing(2),
    }
}));


export default useStyles;
