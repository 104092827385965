import axios from 'axios';
import {setData, getData} from '@utils/helpers/storage';

const getBaseUrl = () => {
    let url = '';
    if (process.env.BACKEND_BASE_URL){
        url = `${process.env.BACKEND_BASE_URL}/api`;
    } else url = 'http://localhost:4000/api';
    return url;
}


const instance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // "X-CSRFToken": getCsrfToken(),
    },

    // withCredentials: process.env.NODE_ENV !== 'production',
});

instance.interceptors.request.use(
    config => {
        const token = getData('bbq_token');
        const account_token = getData('bbq_account_token');
        if (token) config.headers['X-BB-AUTH-TOKEN'] = token;
        if (account_token) config.headers['X-BB-ACCOUNT-ID'] = account_token;
        return config;
    }, error => Promise.reject(error)
);

instance.interceptors.response.use((response) => response,
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('bbq_token');
            localStorage.removeItem('bbq_user');
            window.location.reload();
        }
        return Promise.reject(error);
    });


export default instance;
