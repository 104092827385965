import actionTypes from '@actions/actionTypes';
import { getReducerPayload } from './getPayload';



const userPreloadedState = {
    isLoading: false,
    result: [],
    error: {},
}


const dashboardReducer = (state = userPreloadedState, action) => {
    const { payload, error } = action;
    switch (action.type) {
        default:
            return state;
    }
}


export default dashboardReducer;
