import actionTypes from '@actions/actionTypes';
import { getReducerPayload, getErrorResponse } from './getPayload';
import { dropRight, snakeCase, cloneDeep, keyBy, map, filter, includes, findIndex, isEmpty } from 'lodash';
import apps from '@apps';



const getAdminApps = () => {
    const preloadedApp = map(Object.keys(apps.private), app => {
        return {
            appKey: app,
            list: [],
            isLoading: false,
            error: {},
            status: '',
            paging: { total_count: '', },
        };
    });

    return keyBy(preloadedApp, 'appKey')
}



const userPreloadedState = {
    defaultApp: 'location',
    ...getAdminApps()
};


const updatedList = (list, payload, id) => {
    const index = findIndex(list, item => item.id === id);
    list[index] = payload;
    return list;
}





const adminReducer = (state = userPreloadedState, action) => {
    let { payload, error, appType } = action;

    if (!isEmpty(error)) {
        error = getErrorResponse(error);
    }

    switch (action.type) {
        case actionTypes['FETCH_LIST']:
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: true,
                    status: '',
                }
            }



        case actionTypes['ADD_OPTION']:
        case actionTypes['UPDATE_OPTION']:
        case actionTypes['DELETE_OPTION']:
        case actionTypes['PUBLISH_OPTION']:
        case actionTypes['UNPUBLISH_OPTION']:
        case actionTypes['BULK_DELETE_OPTION']:
        case actionTypes['BULK_PUBLISH_OPTION']:
        case actionTypes['BULK_UPDATE_OPTION']:
        case actionTypes['BULK_UNPUBLISH_OPTION']:
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: true,
                    status: 'published_success',
                }
            }

        case actionTypes['FETCH_LIST_FAILURE']: {
            // const reduxState = cloneDeep(state);
            // reduxState[appType].isLoading = false
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'fetch_list_failure',
                    error,
                }
            }
        }



        case actionTypes['ADD_OPTION_FAILURE']:
        case actionTypes['UPDATE_OPTION_FAILURE']:
        case actionTypes['DELETE_OPTION_FAILURE']:
        case actionTypes['PUBLISH_OPTION_FAILURE']:
        case actionTypes['UNPUBLISH_OPTION_FAILURE']:
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: '',
                    error,
                }
            }

        case actionTypes['FETCH_LIST_SUCCESS']:
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    list: payload.data,
                    paging: { ...state[appType].paging, ...payload.paging },
                    error,
                }
            }

        case actionTypes['ADD_OPTION_SUCCESS']:
            const addOptionPayload = getReducerPayload(payload);
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    list: [addOptionPayload, ...dropRight(state[appType].list)],
                    status: 'created',
                    error: {},
                }
            }

        case actionTypes['UPDATE_OPTION_SUCCESS']:
        case actionTypes['PUBLISH_OPTION_SUCCESS']:
        case actionTypes['UNPUBLISH_OPTION_SUCCESS']:
            const updateOptionPayload = getReducerPayload(payload);
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    list: updatedList(state[appType].list, updateOptionPayload, action.optionId),
                    status: 'updated',
                    error: {},
                }
            }

        case actionTypes['DELETE_OPTION_SUCCESS']:
            return {
                ...state,
                defaultApp: appType,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    list: filter(state[appType].list, item => item.id !== action.optionId),
                    status: 'deleted',
                    error: {},
                }
            }

        case actionTypes['CLEAR_ACTIVE_ADMIN_STATUS']:
            return {
                ...state,
                [payload]: {
                    ...state[payload],
                    status: ''
                }
            }
        case actionTypes['BULK_DELETE_OPTION_SUCCESS']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    list: filter(state[appType].list, item => !includes(payload, item.id)),
                    status: 'bulk_deletion_success',
                    error: {},
                }
            }
        case actionTypes['BULK_DELETE_OPTION_FAILURE']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    status: ''
                }
            }


        case actionTypes['CLEAR_ACTIVE_ADMIN_STATUS']:
            return {
                ...state,
                [payload]: {
                    ...state[payload],
                    status: ''
                }
            }

        case actionTypes['BULK_DELETE_OPTION_SUCCESS']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'bulk_deletion_success',
                    list: filter(state[appType].list, item => !includes(payload, item.id)),
                    error: {},
                }
            }

        case actionTypes['BULK_PUBLISH_OPTION_SUCCESS']:
        case actionTypes['BULK_UNPUBLISH_OPTION_SUCCESS']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: snakeCase(action.type),
                    error: {},
                    list: map(state[appType].list, item => {
                        if (includes(payload, item.id)) {
                            item.status = action.status
                        }
                        return item;
                    }),
                }
            }

        case actionTypes['BULK_PUBLISH_OPTION_FAILURE']:
        case actionTypes['BULK_UNPUBLISH_OPTION_FAILURE']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'bulk_status_change_failed',
                    error
                }
            }

        case actionTypes['BULK_DELETE_OPTION_FAILURE']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'bulk_delete_option_failed',
                    error,
                }
            }
        
        case actionTypes['BULK_UPDATE_OPTION_SUCCESS']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'bulk_update_success',
                    error: {}
                }
            }
        
        case actionTypes['BULK_UPDATE_OPTION_FAILURE']:
            return {
                ...state,
                [appType]: {
                    ...state[appType],
                    isLoading: false,
                    status: 'bulk_update_failed',
                    error
                }
            }


        default:
            return state;
    }
}


export default adminReducer;
