import React, {useEffect} from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Brightness4RoundedIcon from '@material-ui/icons/Brightness4Rounded';
import BrightnessLowRoundedIcon from '@material-ui/icons/BrightnessLowRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import { changeTheme, removeImpersonation} from '@actions/app';
import {signOut} from '@actions/auth';
import {getCorporateName} from '@utils/helpers/misc';


import useStyles from './styles';



const Navbar = (props) => {
    const {handleDrawerOpen, open} = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { activeTheme, accountImpersonated} = useSelector(state => state.app);
    const {user={}} = useSelector(state => state.auth);

    useEffect(() => {
        if (!user.id){
            history.push('/')
        }
    }, [user])

    const toggleTheme = () => {
        dispatch(changeTheme())
    }

    const impersonationRemove = () => {
        dispatch(removeImpersonation())
    }

    const logOut = () => {
        dispatch(signOut())
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <MenuIcon color='inherit' />
                </IconButton>
                {open ? null : (
                    <img 
                        src='https://res.cloudinary.com/brainbout/image/upload/v1615203829/bbLogo_dogqj2.png' 
                        className={classes.brandLogo} 
                    />
                )}
                {!!accountImpersonated.id && (
                    <Chip
                        icon={<LocationCityIcon />}
                        label={getCorporateName(accountImpersonated.account_name)}
                        onDelete={impersonationRemove}
                        color="secondary"
                        className={classes.impersonateAccount}
                    />
                )}
                <div className={classes.rightContainer}>
                    <Typography className={classes.userName}>
                        {user.name}
                    </Typography>
                <Tooltip title="Toggle theme">
                    <IconButton aria-label="toggle theme" className={classes.appBarIcon} onClick={toggleTheme}>
                        <Brightness4RoundedIcon color="inherit" className={classes.toggleTheme} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Log out">
                    <IconButton onClick={logOut} aria-label="Log out" className={classes.appBarIcon}>
                        <PowerSettingsNewIcon color="inherit" className={classes.toggleTheme} />
                    </IconButton>
                </Tooltip>
                </div>
            </Toolbar>
        </AppBar>
    );
}


export default Navbar;
