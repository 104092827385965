import actionTypes from './actionTypes';
import apis from '@apis';
import { unAuthorizedRoles } from '@utils/constants'
import includes from 'lodash/includes';


export const authenticate = (email, password) => async dispatch => {
    dispatch({ type: actionTypes.AUTHENTICATE });
    await apis['authenticate']({email, password})
        .then(response => {
            let resData = (response.data && response.data.data) ? response.data.data : response.data;
            if (resData.role && includes(unAuthorizedRoles, resData.role)) {
                dispatch({
                    type: actionTypes.SHOW_SNACKBAR,
                    payload: {
                        type: 'error',
                        message: 'Not Authorized'
                    },
                })
                dispatch({ 
                    type: actionTypes.AUTHENTICATE_FAILURE, 
                    error: { 
                        errorMsg: "Not Authorized", 
                        code: "Not Authorized" 
                    } 
                });
            } else {
                dispatch({ 
                    type: actionTypes.AUTHENTICATE_SUCCESS, 
                    payload: resData 
                });
            }
        }).catch(error => {
            dispatch({ type: actionTypes.AUTHENTICATE_FAILURE, error });
        });
}

export const signOut = () => dispatch => dispatch({
    type: actionTypes.SIGN_OUT_USER 
});