





import actionTypes from '@actions/actionTypes';
import { getReducerPayload } from './getPayload';



const userPreloadedState = {
    isLoading: false,
    error: {},
    paging: {},
    leaderBoard: {},
}


const dashboardReducer = (state = userPreloadedState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_LEADERBOARD:
        case actionTypes.FETCH_EVENT_COMPETITIONS:
            return { ...state, isLoading: true}

        case actionTypes.FETCH_LEADERBOARD_SUCCESS: {
            const { payload, eventId, competitionId} = action;
            return { 
                ...state,
                isLoading: false,
                leaderBoard: {
                    [competitionId]: payload.data,
                }
            }
        }

        case actionTypes.FETCH_LEADERBOARD_FAILED: {
            const { payload, competitionId } = action;
            return {
                ...state, 
                isLoading: false, 
                leaderBoard: {
                    [competitionId]: {},
                } 
            }
        }

        case actionTypes.FETCH_EVENT_COMPETITIONS_SUCCESS:{
            const { paging={}, data } = action.payload;
            return { 
                ...state, 
                isLoading: false,
                [action.eventId]: {
                    paging: {
                        ...((state[action.eventId] || {}).paging || {}),
                        ...paging,
                    },
                    results: data,
                },
            }

        }

        case actionTypes.FETCH_EVENT_COMPETITIONS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state;
    }
}


export default dashboardReducer;
