import { concat } from 'lodash'; 


export const GENERAL_ERROR_TITLE_MSG = "Something Went Wrong"
export const GENERAL_ERROR_DESCRIPTION_MSG = "Please try to refresh the page"
export const GENERAL_ERROR_FOOTER_MSG = "If problem still exist, Try again after sometime!"


export const encryptionKey = 'brainboutEncryption';
export const drawerWidth = 200;
export const tablePageConfig = {
    order: 'desc',
    page: 0,
    orderBy: 'created_at',
    rowsPerPage: 10,
}

export const competitionConfig = {
    page: 0,
    rowsPerPage: 10,
    orderBy: 'created_at',
    order: 'asc',
}




export const alphabetsChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';


export const customDropdownConfig= {
    create: true,
    multipleSelect: false,
    showCheckbox: false,
    limitTags: 2,
    dropdownLabel: 'label',
}

export const customCheckBoxConfig= {
    labelPlacement: 'end'
}

export const eventTypes = ['series', 'adda', 'quizlet',]
// export const eventTypes = ['series', 'adda', 'quizlet', 'tournament', 'hosting']

export const eventLinks = {
    series: 'competition',
    adda: 'adda',
    quizlet: 'quizlet',
}


export const emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;



export const emailDomainRegex = /^(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;



export const snackbarConfig = {
    transitionTime: 5000,
    message: '',
    anchorOrigin: {vertical: 'bottom', horizontal: 'right' },
    transitionType: 'fade',
}

export const questionFetchType = ['random', 'predefined']

// export const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/
export const phoneRegex = /^[6-9]\d{9}$/

export const adminRoles = ['admin', 'super_contributor', 'super_publisher'];
export const unAuthorizedRoles = ['participant', 'guest_user']
export const participantRoles = concat(adminRoles, unAuthorizedRoles);
export const participantFilterRoles = ['admin', 'super_contributor', 'super_publisher', 'participant', 'guest'];

export const daysInWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wedday',
    'Thurday',
    'Friday',
    'Satday',
]



export const mapDaysOfWeek = {
    0: 'sun',
    1: 'mon',
    2: 'tues',
    3: 'wed',
    4: 'thur',
    5: 'fri',
    6: 'sat',
}


export const backdropLoader = [
    'ball-8bits',
    'ball-atom',
    'ball-beat',
    'ball-circus',
    'ball-climbing-dot',
    'ball-clip-rotate',
    'ball-clip-rotate-multiple',
    'ball-clip-rotate-pulse',
    'ball-elastic-dots',
    'ball-fall',
    'ball-fussion',
    'ball-grid-beat',
    'ball-grid-pulse',
    'ball-newton-cradle',
    'ball-pulse',
    'ball-pulse-rise',
    'ball-pulse-sync',
    'ball-rotate',
    'ball-running-dots',
    'ball-scale',
    'ball-scale-multiple',
    'ball-scale-pulse',
    'ball-scale-ripple',
    'ball-scale-ripple-multiple',
    'ball-spin',
    'ball-spin-clockwise',
    'ball-spin-clockwise-fade',
    'ball-spin-clockwise-fade-rotating',
    'ball-spin-fade',
    'ball-spin-fade-rotating',
    'ball-spin-rotate',
    'ball-square-clockwise-spin',
    'ball-square-spin',
    'ball-triangle-path',
    'ball-zig-zag',
    'ball-zig-zag-deflect',
    'cube-transition',
    'fire',
    'line-scale',
    'line-scale-party',
    'line-scale-pulse-out',
    'line-scale-pulse-out-rapid',
    'line-spin-clockwise-fade',
    'line-spin-clockwise-fade-rotating',
    'line-spin-fade',
    'line-spin-fade-rotating',
    'pacman',
    'square-jelly-box',
    'square-loader',
    'square-spin',
    'timer',
    'triangle-skew-spin',
]

export const loadingMessages = [
"Please Wait... \n 640K ought to be enough for anybody",
"Please Wait... \n the architects are still drafting",
"Please Wait... \n the bits are breeding",
"Please Wait... \n we're building the buildings as fast as we can",
"Please Wait... \n a few bits tried to escape, but we caught them",
"Please Wait... \n and dream of faster computers",
"Please Wait... \n checking the gravitational constant in your locale",
"Please Wait... \n at least you're not on hold",
"Please Wait... \n the server is powered by a lemon and two electrodes",
"Please Wait... \n we're testing your patience",
"Please Wait... \n as if you had any other choice",
"Please Wait... \n why don't you order a sandwich?",
"Please Wait... \n while the satellite moves into position",
"Please Wait... \n the bits are flowing slowly today",
"Please Wait... \n it's still faster than you could draw it",
]


export const dashboardData = [
    {
        label: 'Corporates',
        slug: 'corporate',
        data: {
            active: 100,
            inactive: 20,
        },
        img: 'https://d273f0wru4yscg.cloudfront.net/upload/re-april-week-1-travel-historical-sites-in-movies-430-h-x-525-w-jpg',
    },

    {
        label: 'Participants',
        slug: 'user',
        img: 'https://d273f0wru4yscg.cloudfront.net/upload/re-april-week-1-indian-chief-ministers-430-h-x-525-w-jpg',
        data: {
            active: 200,
            inactive: 30,
        }
    },
    {
        label: 'Categories',
        slug: 'category',
        img: 'https://d273f0wru4yscg.cloudfront.net/upload/april-week-1-fictional-cars-430-h-x-525-w-jpg',
        data: {
            active: 200,
            inactive: 30,
        }
    },
    {
        label: 'Quizzlets',
        slug: 'category?filter=quizlets',
        img: "https://d273f0wru4yscg.cloudfront.net/upload/re-april-week-1-cricket-ipl-venues-430-h-x-525-w-jpg",
        data: {
            active: 200,
            inactive: 30,
        }
    },
    {
        label: 'Questions',
        img: "https://d273f0wru4yscg.cloudfront.net/upload/april-week-1-history-of-world-cinema-430-h-x-525-w-jpg",
        slug: 'question',
        data: {
            active: 200,
            inactive: 30,
        }
    },
    {
        label: 'Events',
        img: "https://d273f0wru4yscg.cloudfront.net/upload/re-april-week-1-cricket-ipl-venues-430-h-x-525-w-jpg",
        slug: 'events',
        data: {
            active: 200,
            inactive: 30,
        }
    },
]

