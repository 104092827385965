import mapKeys from 'lodash/mapKeys';

// export default mapKeys([
//     'AUTHENTICATE',
//     'AUTHENTICATE_SUCCESS',
//     'AUTHENTICATE',
// ]);


const actionTypes = {
    //app actions
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    HIDE_SNACKBAR: 'HIDE_SNACKBAR',
    TOGGLE_THEME: 'TOGGLE_THEME',
    TOGGLE_DRAWER: 'TOGGLE_DRAWER',
    SET_IMPERSONATION: 'SET_IMPERSONATION',
    REMOVE_IMPERSONATION: 'REMOVE_IMPERSONATION',

    //auth actions
    AUTHENTICATE: 'AUTHENTICATE',
    AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
    AUTHENTICATE_FAILURE: 'AUTHENTICATE_FAILURE',
    SIGN_OUT_USER: 'SIGN_OUT_USER',

    
    FETCH_LIST: 'FETCH_LIST',
    FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
    FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',
    FETCH_CATEGORY_LIST_SUCCESS: 'FETCH_CATEGORY_LIST_SUCCESS',

    ADD_OPTION: 'ADD_OPTION',
    ADD_OPTION_SUCCESS: 'ADD_OPTION_SUCCESS',
    ADD_OPTION_FAILURE: 'ADD_OPTION_FAILURE',
    
    UPDATE_OPTION: 'UPDATE_OPTION',
    UPDATE_OPTION_SUCCESS: 'UPDATE_OPTION_SUCCESS',
    UPDATE_OPTION_FAILURE: 'UPDATE_OPTION_FAILURE',
    
    DELETE_OPTION: 'DELETE_OPTION',
    DELETE_OPTION_SUCCESS: 'DELETE_OPTION_SUCCESS',
    DELETE_OPTION_FAILURE: 'DELETE_OPTION_FAILURE',

    PUBLISH_OPTION: 'PUBLISH_OPTION',
    PUBLISH_OPTION_SUCCESS: 'PUBLISH_OPTION_SUCCESS',
    PUBLISH_OPTION_FAILURE: 'PUBLISH_OPTION_FAILURE',
    
    UNPUBLISH_OPTION: 'UNPUBLISH_OPTION',
    UNPUBLISH_OPTION_SUCCESS: 'UNPUBLISH_OPTION_SUCCESS',
    UNPUBLISH_OPTION_FAILURE: 'UNPUBLISH_OPTION_FAILURE',

    CATEGORY_LIST: 'CATEGORY_LIST',
    CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
    CATEGORY_LIST_FAILURE: 'CATEGORY_LIST_FAILURE',
    
    FETCH_LOCATION_LIST_SUCCESS: 'FETCH_LOCATION_LIST_SUCCESS',
    
    CLEAR_ACTIVE_ADMIN_STATUS: 'CLEAR_ACTIVE_ADMIN_STATUS',


    FETCH_LEADERBOARD: 'FETCH_LEADERBOARD',
    FETCH_LEADERBOARD_SUCCESS: 'FETCH_LEADERBOARD_SUCCESS',
    FETCH_LEADERBOARD_FAILED: 'FETCH_LEADERBOARD_FAILED',
    
    FETCH_EVENT_COMPETITIONS: 'FETCH_EVENT_COMPETITIONS',
    FETCH_EVENT_COMPETITIONS_SUCCESS: 'FETCH_EVENT_COMPETITIONS_SUCCESS',
    FETCH_EVENT_COMPETITIONS_FAILED: 'FETCH_EVENT_COMPETITIONS_FAILED',

    BULK_DELETE_OPTION: 'BULK_DELETE_OPTION',
    BULK_DELETE_OPTION_SUCCESS: 'BULK_DELETE_OPTION_SUCCESS',
    BULK_DELETE_OPTION_FAILURE: 'BULK_DELETE_OPTION_FAILURE',

    BULK_PUBLISH_OPTION: 'BULK_PUBLISH_OPTION',
    BULK_PUBLISH_OPTION_SUCCESS: 'BULK_PUBLISH_OPTION_SUCCESS',
    BULK_PUBLISH_OPTION_FAILURE: 'BULK_PUBLISH_OPTION_FAILURE',
    
    BULK_UNPUBLISH_OPTION: 'BULK_UNPUBLISH_OPTION',
    BULK_UNPUBLISH_OPTION_SUCCESS: 'BULK_UNPUBLISH_OPTION_SUCCESS',
    BULK_UNPUBLISH_OPTION_FAILURE: 'BULK_UNPUBLISH_OPTION_FAILURE',

    BULK_UPDATE_OPTION: 'BULK_UPDATE_OPTION',
    BULK_UPDATE_OPTION_SUCCESS: 'BULK_UPDATE_OPTION_SUCCESS',
    BULK_UPDATE_OPTION_FAILURE: 'BULK_UPDATE_OPTION_FAILURE',


}


export default actionTypes;
