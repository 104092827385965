import actionTypes from '@actions/actionTypes';
import { getReducerPayload } from './getPayload';



const locationPreloadedState = {
    isLoading: false,
    list: [],
}


const locationReducer = (state = locationPreloadedState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.FETCH_LOCATION_LIST_SUCCESS:
            return {
                ...state,
                list: getReducerPayload(payload),
            }

        default:
            return state;
    }
}


export default locationReducer;
